import { createContext, useContext, useState, useCallback } from 'react';
import { getRecipeImage, saveRecipeImage } from '../lib/supabase/services/recipes';
import { generateFoodImage } from '../lib/imageGeneration/modelsLab';

interface ImageContextType {
  generatingImages: Set<string>;
  generateImagesForRecipes: (recipes: string[]) => Promise<Record<string, string>>;
}

const ImageContext = createContext<ImageContextType | undefined>(undefined);

export function ImageProvider({ children }: { children: React.ReactNode }) {
  const [generatingImages, setGeneratingImages] = useState<Set<string>>(new Set());

  const generateImagesForRecipes = useCallback(async (recipes: string[]) => {
    setGeneratingImages(new Set(recipes));
    const imageUrls: Record<string, string> = {};

    try {
      await Promise.all(
        recipes.map(async (recipeTitle) => {
          try {
            // Check if image already exists
            const existingImage = await getRecipeImage(recipeTitle);
            if (existingImage) { 
              imageUrls[recipeTitle] = existingImage;
              return;
            }

            // Generate new image
            const imageUrl = await generateFoodImage(recipeTitle);
            await saveRecipeImage(recipeTitle, imageUrl);
            imageUrls[recipeTitle] = imageUrl;
          } catch (error) {
            console.error(`Error generating image for "${recipeTitle}":`, error);
            imageUrls[recipeTitle] = 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c?fit=crop&w=768&h=768&q=80';
          }
        })
      );
      return imageUrls;
    } catch (error) {
      console.error('Error generating images:', error);
      return {};
    } finally {
      setGeneratingImages(new Set());
    }
  }, []);

  return (
    <ImageContext.Provider value={{ generatingImages, generateImagesForRecipes }}>
      {children}
    </ImageContext.Provider>
  );
}

export function useImages() {
  const context = useContext(ImageContext);
  if (context === undefined) {
    throw new Error('useImages must be used within an ImageProvider');
  }
  return context;
}