import { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../lib/supabase/client';
import { signInWithEmail, signUpWithEmail, signOut as authSignOut } from '../lib/supabase/auth/core';
import { getCurrentUser, getCurrentSession } from '../lib/supabase/auth/session';
import type { AuthUser, AuthState } from '../lib/supabase/auth/types';

interface AuthContextType extends AuthState {
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, name?: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<AuthState>({
    user: null,
    loading: true,
    error: null
  });

  useEffect(() => {
    const initAuth = async () => {
      try {
        const session = await getCurrentSession();
        if (session?.user) {
          setState(prev => ({
            ...prev,
            user: {
              id: session.user.id,
              email: session.user.email!,
              name: session.user.user_metadata.name
            },
            loading: false
          }));
        } else {
          setState(prev => ({ ...prev, loading: false }));
        }
      } catch (error) {
        console.error('Error initializing auth:', error);
        setState(prev => ({ ...prev, loading: false }));
      }
    };

    initAuth();

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (session?.user) {
          setState(prev => ({
            ...prev,
            user: {
              id: session.user.id,
              email: session.user.email!,
              name: session.user.user_metadata.name
            }
          }));
        } else {
          setState(prev => ({ ...prev, user: null }));
        }
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      setState(prev => ({ ...prev, loading: true, error: null }));
      const { user } = await signInWithEmail(email, password);
      setState(prev => ({ ...prev, user }));
    } catch (error: any) {
      setState(prev => ({ ...prev, error: error.message }));
      throw error;
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const signUp = async (email: string, password: string, name?: string) => {
    try {
      setState(prev => ({ ...prev, loading: true, error: null }));
      const { user } = await signUpWithEmail({ email, password, name });
      setState(prev => ({ ...prev, user }));
    } catch (error: any) {
      setState(prev => ({ ...prev, error: error.message }));
      throw error;
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  const signOut = async () => {
    try {
      setState(prev => ({ ...prev, loading: true, error: null }));
      await authSignOut();
      setState(prev => ({ ...prev, user: null }));
    } catch (error: any) {
      setState(prev => ({ ...prev, error: error.message }));
      throw error;
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signUp,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}