import { useState } from 'react';
import { Clock } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useSubscription } from '../hooks/useSubscription';

export default function TrialBanner() {
  const [isDismissed, setIsDismissed] = useState(false);
  const { subscription, isTrialActive } = useSubscription();
  const location = useLocation();

  // Don't show banner on homepage or auth pages
  if (
    location.pathname === '/' || 
    ['/login', '/signup', '/reset-password'].includes(location.pathname) ||
    !isTrialActive() || 
    isDismissed
  ) {
    return null;
  }

  const daysLeft = Math.ceil(
    (new Date(subscription?.validUntil || '').getTime() - new Date().getTime()) /
    (1000 * 60 * 60 * 24)
  );

  return (
    <div className="fixed top-0 left-0 right-0 z-30 bg-emerald-50 border-b border-emerald-100">
      <div className="max-w-7xl mx-auto px-4 py-1">
        <div className="flex items-center justify-center gap-2 text-xs text-emerald-800">
          <Clock className="h-3 w-3 text-emerald-600" />
          <span>
            Nog {daysLeft} {daysLeft === 1 ? 'dag' : 'dagen'} proefperiode
          </span>
          <Link 
            to="/settings?section=subscription" 
            className="font-medium text-emerald-700 hover:text-emerald-800 transition-colors"
          >
            Upgrade naar Premium
          </Link>
        </div>
      </div>
    </div>
  );
}