import { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { getUserPreferences, updateUserPreferences } from '../lib/supabase/services/preferences';
import type { UserPreferences } from '../lib/supabase/types';

interface OnboardingContextType {
  preferences: UserPreferences | null;
  isLoading: boolean;
  updatePreferences: (updates: Partial<UserPreferences>) => Promise<UserPreferences>;
  setOnboardingComplete: () => Promise<void>;
}

const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

export function OnboardingProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  const [preferences, setPreferences] = useState<UserPreferences | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadPreferences = async () => {
      if (!user) {
        setPreferences(null);
        setIsLoading(false);
        return;
      }

      try {
        const prefs = await getUserPreferences();
        setPreferences(prefs);
      } catch (error) {
        console.error('Error loading preferences:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadPreferences();
  }, [user]);

  const updatePreferences = async (updates: Partial<UserPreferences>) => {
    if (!user) throw new Error('User not authenticated');

    try {
      const updatedPreferences = await updateUserPreferences(updates);
      setPreferences(updatedPreferences);
      return updatedPreferences;
    } catch (error) {
      console.error('Error updating preferences:', error);
      throw error;
    }
  };

  const setOnboardingComplete = async () => {
    try {
      const updatedPreferences = await updateUserPreferences({
        has_completed_onboarding: true
      });
      setPreferences(updatedPreferences);
      return updatedPreferences;
    } catch (error) {
      console.error('Error completing onboarding:', error);
      throw error;
    }
  };

  return (
    <OnboardingContext.Provider 
      value={{ 
        preferences, 
        isLoading, 
        updatePreferences,
        setOnboardingComplete
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
}

export function useOnboarding() {
  const context = useContext(OnboardingContext);
  if (context === undefined) {
    throw new Error('useOnboarding must be used within an OnboardingProvider');
  }
  return context;
}