import { saveRecipeImage, getRecipeImage } from '../supabase/services/recipes';

const API_KEY = import.meta.env.VITE_MODELSLAB_API_KEY;
const API_URL = 'https://modelslab.com/api/v6/realtime/text2img';

interface ModelsLabResponse {
  status: string;
  generationTime: number;
  id: number;
  output: string[];
  proxy_links: string[];
  meta: {
    base64: string;
    enhance_prompt: string;
    enhance_style: string | null;
    file_prefix: string;
    guidance_scale: number;
    height: number;
    instant_response: string;
    n_samples: number;
    negative_prompt: string;
    outdir: string;
    prompt: string;
    safety_checker: string;
    safety_checker_type: string;
    seed: number;
    temp: string;
    width: number;
  };
}

const FALLBACK_IMAGE = 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c?fit=crop&w=512&h=512&q=80';

const createFoodPrompt = (title: string): string => {
  const cleanTitle = title
    .replace(/met|en|&|,|\(|\)|\./gi, ' ')
    .replace(/\s+/g, ' ')
    .trim();
  
  return `professional food photography of ${cleanTitle}, food on white plate, commercial food photography, professional lighting, high-end restaurant presentation, natural lighting, sharp focus, photorealistic, food styling, appetizing, centered composition, clean background, award winning food photography, 8k uhd, masterful`;
};

const createNegativePrompt = (): string => {
  return 'bad quality, low quality, illustration, painting, drawing, cartoon, anime, 3d render, watermark, text, logo, oversaturated, distorted, deformed, blurry, noisy, ugly, burnt food, raw food, multiple plates, hands, people, cluttered, dark, grainy';
};

const verifyImageUrl = async (url: string): Promise<boolean> => {
  try {
    const response = await fetch(url, { 
      method: 'HEAD',
      headers: {
        'Accept': 'image/webp,image/png,image/jpeg',
      }
    });
    return response.ok && response.headers.get('content-type')?.startsWith('image/');
  } catch {
    return false;
  }
};

export const generateFoodImage = async (recipeTitle: string): Promise<string> => {
  if (!recipeTitle?.trim()) {
    return FALLBACK_IMAGE;
  }

  try {
    // Check if image already exists in Supabase
    const existingImage = await getRecipeImage(recipeTitle);
    if (existingImage) {
      const isValid = await verifyImageUrl(existingImage);
      if (isValid) {
        return existingImage;
      }
    }

    if (!API_KEY) {
      throw new Error('API key not configured');
    }

    // Make request using fetch
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        key: API_KEY,
        prompt: createFoodPrompt(recipeTitle),
        negative_prompt: createNegativePrompt(),
        width: "512",
        height: "512",
        safety_checker: false,
        seed: null,
        samples: 1,
        base64: false,
        guidance_scale: 8.5,
        scheduler: "DPMSolverMultistep"
      })
    });

    if (!response.ok) {
      throw new Error(`API Error: ${response.status} ${response.statusText}`);
    }

    const data: ModelsLabResponse = await response.json();

    if (data.status !== 'success' || !data.output?.[0]) {
      throw new Error('Invalid API response');
    }

    const imageUrl = data.output[0];
    
    // Verify the generated image URL is accessible
    const isValid = await verifyImageUrl(imageUrl);
    if (!isValid) {
      throw new Error('Generated image URL is not accessible');
    }

    // Save to Supabase
    await saveRecipeImage(recipeTitle, imageUrl);

    return imageUrl;

  } catch (error) {
    console.error(`Error generating image for "${recipeTitle}":`, error);
    
    // Use fallback image
    await saveRecipeImage(recipeTitle, FALLBACK_IMAGE);
    
    return FALLBACK_IMAGE;
  }
};