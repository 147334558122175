import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ImageProvider } from './context/ImageContext';
import { OnboardingProvider } from './context/OnboardingContext';
import Navbar from './components/Navbar';
import LoadingSpinner from './components/LoadingSpinner';
import ProtectedRoute from './components/ProtectedRoute';
import TrialBanner from './components/TrialBanner';

// Lazy load pages
const HomePage = lazy(() => import('./pages/HomePage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const SignUpPage = lazy(() => import('./pages/SignUpPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const OnboardingPage = lazy(() => import('./pages/OnboardingPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const PreferencesPage = lazy(() => import('./pages/dashboard/PreferencesPage'));
const MenuDashboardPage = lazy(() => import('./pages/dashboard/MenuDashboardPage'));
const MenuChatPage = lazy(() => import('./pages/dashboard/MenuChatPage'));
const RecipeDetailPage = lazy(() => import('./pages/RecipeDetailPage'));
const ArchivePage = lazy(() => import('./pages/ArchivePage'));
const FavoritesPage = lazy(() => import('./pages/FavoritesPage'));
const CheckoutPage = lazy(() => import('./pages/stripe/CheckoutPage'));
const SuccessPage = lazy(() => import('./pages/stripe/SuccessPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));
const ShoppingListPage = lazy(() => import('./pages/ShoppingListPage'));

export default function App() {
  return (
    <AuthProvider>
      <OnboardingProvider>
        <ImageProvider>
          <div className="min-h-screen bg-gray-50">
            <Navbar />
            <TrialBanner />
            <Suspense fallback={
              <div className="min-h-screen flex items-center justify-center">
                <LoadingSpinner />
              </div>
            }>
              <Routes>
                {/* Public routes */}
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />

                {/* Protected routes */}
                <Route element={<ProtectedRoute />}>
                  <Route path="/onboarding" element={<OnboardingPage />} />
                  <Route path="/dashboard" element={<DashboardPage />} />
                  <Route path="/dashboard/preferences" element={<PreferencesPage />} />
                  <Route path="/menu/:menuId" element={<MenuDashboardPage />} />
                  <Route path="/menu/new/chat" element={<MenuChatPage />} />
                  <Route path="/menu/:menuId/recipe/:recipeTitle" element={<RecipeDetailPage />} />
                  <Route path="/menu/:menuId/shopping-list" element={<ShoppingListPage />} />
                  <Route path="/archive" element={<ArchivePage />} />
                  <Route path="/favorites" element={<FavoritesPage />} />
                  <Route path="/settings" element={<SettingsPage />} />
                  <Route path="/checkout" element={<CheckoutPage />} />
                  <Route path="/payment/success" element={<SuccessPage />} />
                </Route>

                {/* Fallback */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Suspense>
          </div>
        </ImageProvider>
      </OnboardingProvider>
    </AuthProvider>
  );
}