import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { supabase } from '../lib/supabase/client';

export function useSubscription() {
  const { user } = useAuth();
  const [subscription, setSubscription] = useState<{
    status: string;
    validUntil: string;
  } | null>(null);

  useEffect(() => {
    const loadSubscription = async () => {
      if (!user) return;

      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('subscription_status, subscription_valid_until')
          .eq('id', user.id)
          .single();

        if (error) throw error;

        setSubscription({
          status: data.subscription_status,
          validUntil: data.subscription_valid_until
        });
      } catch (error) {
        console.error('Error loading subscription:', error);
      }
    };

    loadSubscription();
  }, [user]);

  const isValidSubscription = () => {
    if (!subscription) return false;
    return new Date(subscription.validUntil) > new Date();
  };

  const isTrialActive = () => {
    if (!subscription) return false;
    return subscription.status === 'trial' && isValidSubscription();
  };

  const isPremium = () => {
    if (!subscription) return false;
    return subscription.status === 'premium' && isValidSubscription();
  };

  const canGenerateMenu = () => {
    return isPremium() || isTrialActive();
  };

  return {
    isTrialActive,
    isPremium,
    canGenerateMenu,
    subscription
  };
}