import { supabase } from '../client';
import type { UserPreferences } from '../types';

export async function getUserPreferences() {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return null;

  const { data, error } = await supabase
    .from('user_preferences')
    .select('*')
    .eq('user_id', user.id)
    .single();

  if (error) throw error;
  return data as UserPreferences;
}

export async function updateUserPreferences(updates: Partial<UserPreferences>) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  // Get current preferences first
  const currentPrefs = await getUserPreferences();
  if (!currentPrefs) {
    throw new Error('User preferences not found');
  }

  const { data, error } = await supabase
    .from('user_preferences')
    .update({
      ...currentPrefs,
      ...updates,
      updated_at: new Date().toISOString()
    })
    .eq('user_id', user.id)
    .select()
    .single();

  if (error) throw error;
  if (!data) throw new Error('Failed to update preferences');

  return data as UserPreferences;
}