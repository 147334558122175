import { supabase } from '../client';
import type { AuthResponse, SignUpData } from './types';
import { getProfile } from './profile';

export async function signUpWithEmail({ email, password, name }: SignUpData): Promise<AuthResponse> {
  try {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: { name }
      }
    });

    if (error) throw error;
    if (!data.user) throw new Error('Account aanmaken mislukt');

    // Wait briefly for trigger to create profile
    await new Promise(resolve => setTimeout(resolve, 1500));

    // Verify profile was created
    const profile = await getProfile(data.user.id);
    if (!profile) {
      throw new Error('Profiel aanmaken mislukt');
    }

    return {
      user: {
        id: data.user.id,
        email: data.user.email!,
        name: profile.name || undefined
      }
    };
  } catch (error: any) {
    console.error('Signup error:', error);
    throw error;
  }
}

export async function signInWithEmail(email: string, password: string): Promise<AuthResponse> {
  try {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
      options: {
        redirectTo: `${window.location.origin}/dashboard`
      }
    });

    if (error) throw error;
    if (!data.user) throw new Error('Ongeldige inloggegevens');

    const profile = await getProfile(data.user.id);
    if (!profile) {
      // Create profile if it doesn't exist
      const { data: newProfile, error: profileError } = await supabase
        .from('profiles')
        .insert([{
          id: data.user.id,
          email: data.user.email,
          subscription_status: 'trial',
          subscription_valid_until: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString()
        }])
        .select()
        .single();

      if (profileError) throw profileError;
      if (!newProfile) throw new Error('Failed to create profile');
      
      return {
        user: {
          id: data.user.id,
          email: data.user.email!,
          name: undefined
        }
      };
    }

    return {
      user: {
        id: data.user.id,
        email: data.user.email!,
        name: profile.name || undefined
      }
    };
  } catch (error: any) {
    console.error('Signin error:', error);
    throw error;
  }
}

export async function signOut(): Promise<void> {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
}