import { supabase } from '../client';
import type { FavoriteRecipe, RecipeImage } from '../types';

export async function addFavoriteRecipe(recipe: Omit<FavoriteRecipe, 'id' | 'user_id' | 'created_at'>) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Not authenticated');

  const { data, error } = await supabase
    .from('favorite_recipes')
    .insert({
      ...recipe,
      user_id: user.id
    })
    .select()
    .single();

  if (error) throw error;
  return data as FavoriteRecipe;
}

export async function removeFavoriteRecipe(id: string) {
  const { error } = await supabase
    .from('favorite_recipes')
    .delete()
    .eq('id', id);

  if (error) throw error;
}

export async function getFavoriteRecipes() {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return [];

  const { data, error } = await supabase
    .from('favorite_recipes')
    .select('*')
    .eq('user_id', user.id)
    .order('created_at', { ascending: false });

  if (error) throw error;
  return data as FavoriteRecipe[];
}

export async function saveRecipeImage(recipeTitle: string, imageUrl: string) {
  const { data, error } = await supabase
    .from('recipe_images')
    .upsert({
      recipe_title: recipeTitle,
      image_url: imageUrl,
      updated_at: new Date().toISOString()
    })
    .select()
    .single();

  if (error) throw error;
  return data as RecipeImage;
}

export async function getRecipeImage(recipeTitle: string) {
  const { data, error } = await supabase
    .from('recipe_images')
    .select('image_url')
    .eq('recipe_title', recipeTitle)
    .single();

  if (error && error.code !== 'PGRST116') throw error;
  return data?.image_url;
}